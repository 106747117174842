import { Spinner, Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import { useEffect, useCallback, useState } from "react";
import {
  dataTink,
  TinkAvailableBank,
  TinkAccount,
} from "../../../data/dataTink";
import TinkIntegrationClient from "../TinkIntegrationClient/TinkIntegrationClient";
import * as H from "history";
import { TranslatedText } from "../../TranslatedText";
import { Link, useLocation } from "react-router-dom";
import { dataCustomerTrackingService } from "../../../data/dataCustomerTracking";
import { featureTrackingManualBackup } from "../../../utils/featureTrackingPath";

interface Props {
  bank: TinkAvailableBank;
  onComplete: (
    accounts: TinkAccount[],
    accountVerificationReportId: string
  ) => void;
  fallbackOptions?: {
    locationDescriptor: H.LocationDescriptor;
    buttonText: string | React.ReactNode;
  };
}

interface SessionResponse {
  clientId: string;
  sessionId: string;
  providerName: string;
}

export function AccountLoading({ bank, onComplete, fallbackOptions }: Props) {
  const [sessionData, setSessionData] = useState<SessionResponse | null>(null);
  const [error, setError] = useState<string>();
  const location = useLocation();

  const createSession = useCallback(() => {
    dataTink
      .createSession(bank.id)
      .then((resp: SessionResponse) => {
        setSessionData(resp);
      })
      .catch((err) => {
        setError("Failed to create Tink session");
      });
  }, [bank.id]);

  useEffect(() => {
    createSession();
  }, [createSession]);

  const handleSuccess = useCallback(
    (result: any, accountVerificationReportId: string) => {
      onComplete(result.accounts, accountVerificationReportId);
    },
    [onComplete]
  );

  const handleError = useCallback((error: any) => {
    setError("An error occurred during the Tink Link flow");
  }, []);

  if (error) {
    dataCustomerTrackingService.postEvent(
      featureTrackingManualBackup(location.pathname)
    );

    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <h2>
              <TranslatedText id="klarna.account-loading.error-header" />
            </h2>
            <p>
              <TranslatedText id="klarna.account-loading.error-body" />
            </p>
          </div>
        </Snackbar>
        {fallbackOptions && (
          <Button
            variant="primary"
            block
            component={Link}
            to={fallbackOptions.locationDescriptor}
            label={fallbackOptions.buttonText}
          />
        )}
      </div>
    );
  }

  if (!sessionData) {
    return <Spinner />;
  }

  return (
    <TinkIntegrationClient
      clientToken={sessionData.clientId}
      sessionId={sessionData.sessionId}
      providerName={sessionData.providerName}
      onSuccess={handleSuccess}
      onError={handleError}
    />
  );
}
